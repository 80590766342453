import React from "react";

import { graphql } from "gatsby";
import { useI18next, useTranslation } from "gatsby-plugin-react-i18next";
import SanityImage from "gatsby-plugin-sanity-image";
import PropTypes from "prop-types";

import GetInternalLinkContext from "../utils/GetInternalLinkContext";

const ProjectCard = ({ data, parentSlug, className }) => {
  const { t } = useTranslation();
  const { language } = useI18next();
  const lang = language === "de-DE" ? "" : `/${language}`;
  const link = GetInternalLinkContext({ id: data.id, slug: `${lang}/${t(parentSlug)}/${data.slug?.current}` });

  return (
    <div className="mb-60">
      <a href={link.path} title={link.title} className={className}>
        {data.images?.images?.length > 0 && (
          <SanityImage
            {...data.images?.images[0]}
            width={275}
            className="w-full"
          />
        )}
        <div className="text-body2 mb-3 mt-18" />
        <h5>{data.title}</h5>
      </a>
    </div>
  );
};

export const query = graphql`
  fragment ProjectCard on SanityProject {
    id
    title
    type
    images {
      images {
        ...ImageWithPreview
        alt
      }
    }
  }
`;

ProjectCard.propTypes = {
  className: PropTypes.string,
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
  parentSlug: PropTypes.string,
};

ProjectCard.defaultProps = {
  className: null,
  parentSlug: "research",
};

export default ProjectCard;
