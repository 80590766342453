import React from "react";

import classNames from "classnames";
import { graphql } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import ContentContainer from "../../components/ContentContainer/ContentContainer";
import Masonry from "../../components/Masonry/Masonry";
import Section from "../../components/Section/Section";
import SidebarFilters from "../../components/SidebarFilters/SidebarFilters";
import TabNavigation from "../../components/TabNavigation/TabNavigation";
import {
  addProjectsFilter,
  addResearchFilter,
  removeProjectsFilter,
  removeResearchFilter,
} from "../../features/ResearchAndProjectsFilterSlice";
import useMediaQuery from "../../hooks/useMediaQuery";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import ImageSlider from "../ImageSlider/ImageSlider";
import MainLayout from "../layouts/MainLayout";
import ProjectCard from "../Project/ProjectCard";
import Seo from "../Seo/Seo";

const ResearchAndProjectsOverviewPage = ({
  data: {
    page,
    projects,
    researches,
  },
}) => {
  const {
    projectsFilters,
    researchFilters,
  } = useSelector((state) => state.researchAndProjectsFilter);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isMobile = useMediaQuery("(max-width: 1024px)");

  return (
    <MainLayout breadcrumb={<Breadcrumb id={page?.id} />}>
      <Section>
        <ContentContainer>
          <h1>{page?.title}</h1>
        </ContentContainer>
        <div className="-mt-8 lg:-mt-18">
          <ImageSlider data={page?.slider} />
        </div>
      </Section>
      <div className="lg:mb-60" />
      <Section>
        <ContentContainer>
          <Masonry breakpointCols={isMobile ? 1 : 2}>
            {projects?.nodes?.map(
              (project, index) => <ProjectCard key={project.id} data={project} parentSlug="project_slug" className={classNames({ "mt-60": index === 0 || index === 2 })} />,
            )}
            {researches?.nodes?.map(
              (project, index) => <ProjectCard key={project.id} data={project} parentSlug="forschung_slug" className={classNames({ "mt-60": index === 0 || index === 2 })} />,
            )}
          </Masonry>
        </ContentContainer>
      </Section>
    </MainLayout>
  );
};

export const query = graphql`
  query ($id: String!, $graphqlLocale: [String]) {
    locales: allLocale {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    page: sanityResearchAndProjectsOverview(id: {eq: $id}) {
      id
      title
      _rawQuickInfoText
      slider {
        ...ImageSlider
      }
      Metadata {
        ...MetaData
      }
    }
    projects: allSanityProject (
      filter: {images: {images: {elemMatch: {_key: {ne: null}}}}, i18n_lang: {in: $graphqlLocale} }
    ) {
      totalCount
      nodes {
        id
        slug {
          current
        }
        title
        type
        images {
          images {
            ...ImageWithPreview
            alt
          }
        }
      }
    }
    researches: allSanityResearch (
      filter: {images: {images: {elemMatch: {_key: {ne: null}}}}, i18n_lang: {in: $graphqlLocale} }
    ) {
      totalCount
      nodes {
        id
        slug {
          current
        }
        title
        type
        images {
          images {
            ...ImageWithPreview
            alt
          }
        }
      }
    }
  }
`;

ResearchAndProjectsOverviewPage.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

const seo = ({ data }) => (
  <Seo data={data.page?.Metadata} />
);

seo.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export const Head = seo;

export default ResearchAndProjectsOverviewPage;
